/** @jsx jsx */
import { jsx } from "theme-ui"
const Logo = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  sx={{
    height: props.size,
    width: props.size,
  }}
  fill="none"
  viewBox="0 0 24 24"
  version="1.1"
>
  <path
    fill={props.color}
    d="M18.667 17.863a9.954 9.954 0 001.666-5.53 9.954 9.954 0 00-1.666-5.53V4H22v16.667h-3.333v-2.804z"
  ></path>
  <ellipse
    cx="10.333"
    cy="12.333"
    fill={props.color}
    rx="8.333"
    ry="8.333"
    width="183"
    height="14"
  ></ellipse>
</svg>
)

Logo.defaultProps = {
  color: "white",
}

export default Logo
