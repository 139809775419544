/** @jsx jsx */
/* eslint-disable jsx-a11y/no-autofocus, react/jsx-no-target-blank */
import { jsx } from "theme-ui"
import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { FaSearch } from "react-icons/fa"
import { useCombobox } from "downshift"
import Fuse from "fuse.js"

import * as icons from "../utils/icons"
import { rhythm } from "../utils/typography"
import Logo from "./logo"
import BlogHeader from "../blog-header.svg"

// Google analytics
import { OutboundLink } from "gatsby-plugin-google-gtag"

function SearchInput(props) {
  const [text, setText] = React.useState("")
  const [focused, setFocused] = React.useState(false)

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          texts {
            searchPlaceholderText
          }
        }
      }
      articles: allMarkdownRemark {
        nodes {
          id
          fields {
            slug
            collection {
              icon
            }
          }
          frontmatter {
            title
            description
          }
          headings {
            # depth
            value
          }
          # excerpt(format: PLAIN)
        }
      }
    }
  `)

  const items = data.articles.nodes

  const fuse = React.useMemo(
    () =>
      new Fuse(items, {
        shouldSort: true,
        tokenize: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "frontmatter.title",
          "frontmatter.description",
          "headings.value",
        ],
      }),
    [items]
  )

  const [inputItems, setInputItems] = React.useState(data.articles.nodes)

  const combobox = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(fuse.search(inputValue).map((node) => node.item))
    },
    itemToString: (node) => (node ? node.frontmatter.title : ""),
    onSelectedItemChange: ({ selectedItem }) => {
      navigate(selectedItem.fields.slug)
    },
  })

  return (
    <div sx={{ position: "relative" }} {...combobox.getComboboxProps()}>
      <label
        htmlFor="search"
        {...combobox.getLabelProps({
          htmlFor: "search",
        })}
        sx={{
          position: "absolute",
          left: "18pt",
          top: "0",
          bottom: "0",
          display: "flex",
          alignItems: "center",
          cursor: "text",
        }}
      >
        <FaSearch color={focused ? "#828A97" : "#989FA5"} />
      </label>
      <input
        id="search"
        type="text"
        value={text}
        autoFocus
        onChange={(event) => setText(event.target.value)}
        placeholder={data.site.siteMetadata.texts.searchPlaceholderText}
        autoComplete="off"
        sx={{
          backgroundColor: "rgba(255,255,255,1)",
          transition: "background .4s, box-shadow .2s",
          width: "100%",
          padding: "20px 32px 21px 56px",
          background: "rgba(255,255,255,1)",
          border: "none",
          outline: "none",
          color: "searchTextColor",
          fontSize: "18px",
          lineHeight: "18px",
          borderRadius: "8px",
          "&:focus": {
            backgroundColor: "white",
            boxShadow: `0px 2px 8px rgba(29, 39, 48, 0.25)`,
            color: "searchTextFocusColor",
          },
          "::placeholder": {
            color: "searchTextPlaceholderColor",
          },
          "&:focus::placeholder": {
            color: "searchTextFocusPlaceholderColor",
          },
        }}
        {...combobox.getInputProps({
          id: "search",
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
        })}
      />
      <div
        {...combobox.getMenuProps()}
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          top: "calc(20px + 21px + 18px)",
          alignItems: "center",
          cursor: "text",
          background: "white",
          color: "comboboxColor",
          zIndex: 4,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          boxShadow: "0px 2px 8px rgba(29, 39, 48, 0.25)",
        }}
      >
        {combobox.isOpen &&
          inputItems.map((node, index) => {
            // skip drafts and "hidden" articles (ones without a collection)
            if (!node.fields || !node.fields.collection) return null

            const icon = jsx(
              icons[node.fields.collection.icon],
              { sx: { color: "iconColor" }, size: "2rem" },
              null
            )
            return (
              <Link
                key={node.id}
                to={node.fields.slug}
                sx={{
                  display: "flex",
                  pl: 3,
                  pr: 5,
                  py: 3,
                  textDecoration: "none",
                  background: "#fff",
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",

                  "&:hover": {
                    textDecoration: "none",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",

                    h3:{
                      color: "#1774C6"
                    }
                  },
                }}
                {...combobox.getItemProps({ item: node, index })}
              >
                <div
                  sx={{
                    display: ["none", "flex"],
                    alignItems: "center",
                    pr: 3,
                  }}
                >
                  {icon}
                </div>
                <div sx={{ flex: "auto" }}>
                  <h3 sx={{ my: 0, fontSize: 3, textTransform: "lowercase", color: "#1D2730" }}>{node.frontmatter.title}</h3>
                  <p
                    sx={{
                      my: 0,
                      color: "articleDescriptionColor",
                      fontSize: [1, 2],
                    }}
                  >
                    {node.frontmatter.description}
                  </p>
                </div>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

class Layout extends React.Component {
  render() {
    const { location, children, description } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>
        <div
          sx={{
            py: 3,
            color: "headerText",
            backgroundColor: "headerBackground",
            position: 'relative'
          }}
        >
          <div
            sx={{
              backgroundImage: 'url('+ BlogHeader +')',
              backgroundPosition: 'center',
              backgroundSize: 'auto 100%',
              backgroundRepeat: "repeat-x",
              position: 'absolute',
              top: '100%',
              width: '100%',
              height: '40px'
            }}
          />
          {location.pathname !== 'blah' ? (
            <header
              sx={{
                mx: `auto`,
                maxWidth: rhythm(30),
                fontSize: 3,
                px: [2, 4],
                pt: 4,
                pb: 2,
              }}
            >
            <Link
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 16,
                textDecoration: "none"
              }}
              to = "/"
            >
              <Logo color="white" size={["36px", "36px"]} />
              <div
                sx={{
                  display: "inline-block",
                  marginLeft: '8px',
                  fontFamily: 'Montserrat Alternates',
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: 24
                }}
              >pulse
              </div>
            </Link>
              <SearchInput />
              <Link
                  sx={{
                    boxShadow: `none`,
                    fontSize: 16,
                    textDecoration: `none`,
                    marginTop: 8,
                    color: `#fff`,
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                  to={`https://ampmusic.co`}
                  target="_blank"
                >
                  Go to <span 
                    sx={{
                      fontFamily: 'Montserrat Alternates',
                      fontWeight: 600,
                      fontSize: 14
                    }}
                  >ampmusic.co  </span>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path d="M10.625 8H9.875C9.77554 8 9.68016 8.03951 9.60983 8.10983C9.53951 8.18016 9.5 8.27554 9.5 8.375V11H2V3.5H5.375C5.47446 3.5 5.56984 3.46049 5.64016 3.39016C5.71049 3.31984 5.75 3.22446 5.75 3.125V2.375C5.75 2.27554 5.71049 2.18016 5.64016 2.10984C5.56984 2.03951 5.47446 2 5.375 2H1.625C1.32663 2 1.04048 2.11853 0.829505 2.3295C0.618526 2.54048 0.5 2.82663 0.5 3.125L0.5 11.375C0.5 11.6734 0.618526 11.9595 0.829505 12.1705C1.04048 12.3815 1.32663 12.5 1.625 12.5H9.875C10.1734 12.5 10.4595 12.3815 10.6705 12.1705C10.8815 11.9595 11 11.6734 11 11.375V8.375C11 8.27554 10.9605 8.18016 10.8902 8.10983C10.8198 8.03951 10.7245 8 10.625 8ZM11.9375 0.5H8.9375C8.43664 0.5 8.18633 1.10727 8.53906 1.46094L9.37648 2.29836L3.66406 8.00867C3.61162 8.06093 3.57001 8.12303 3.54162 8.1914C3.51323 8.25977 3.49862 8.33308 3.49862 8.40711C3.49862 8.48114 3.51323 8.55445 3.54162 8.62282C3.57001 8.69119 3.61162 8.75329 3.66406 8.80555L4.19539 9.33594C4.24765 9.38838 4.30974 9.42999 4.37812 9.45838C4.44649 9.48677 4.5198 9.50138 4.59383 9.50138C4.66786 9.50138 4.74117 9.48677 4.80954 9.45838C4.87791 9.42999 4.94001 9.38838 4.99227 9.33594L10.7019 3.62469L11.5391 4.46094C11.8906 4.8125 12.5 4.56641 12.5 4.0625V1.0625C12.5 0.913316 12.4407 0.770242 12.3352 0.664752C12.2298 0.559263 12.0867 0.5 11.9375 0.5V0.5Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </Link>
            </header>
          ) : (
            <header
              sx={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: rhythm(30),
                px: [2, 4],
                pt: 4,
                pb: 2,
              }}
            >
              <h3
                sx={{
                  mt: 0,
                  mb: 3,
                }}
              >
                <Link
                  sx={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `logoColor`,
                    "&:hover": {
                      textDecoration: "none",
                      color: "logoColor",
                    },
                  }}
                  to={`/`}
                >
                  <Logo color="white" size={["36px", "48px"]}/>
                </Link>

              </h3>
              {location.pathname === rootPath && <p>{description}</p>}
              <SearchInput />
            </header>
          )}
        </div>
        <div
          style={{
            background: "#E7EAF0",
            paddingTop: "40px"
          }}
        >
          <main
            sx={{
              mx: `auto`,
              maxWidth: rhythm(30),
              px: [2, 4],
              py: [3],
            }}
          >
            {children}
          </main>
        </div>
        <footer
          sx={{
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            textAlign: "center",
            color: "footerTextColor",
            fontSize: 1,
            backgroundColor: "#E7EAF0"
          }}
        >
          <Link
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: "center",
              textDecoration: "none"
            }}
            to={`https://ampmusic.co`}
            target="_blank"
          >
            <Logo color="#1774C6" size="24px" />
            <div
              sx={{
                marginLeft: '8px',
                fontFamily: "Montserrat Alternates",
                fontWeight: 600,
                color: "#1D2730",
                fontSize: "16px",
              }}
            >ampmusic.co</div>
          </Link>
          <div sx={{ mt: 2 }}>
            {/* Built with */}
            {` `}
            {/*
              PLEASE DO NOT REMOVE THIS LINK.

              A lot of unpaid time is spent on making and maintaining the 
              center. Nothing is expected in return. Keeping this link here
              is the only small thing asked in return. So please don't remove it.

              You are amazing for keeping it here, thank you.
            */}
            <a
              href="https://help.dferber.de"
              target="_blank"
              sx={{
                color: "footerTextColor",
                textDecoration: "underline",
                "&:hover": {
                  color: "footerTextHoverColor",
                },
              }}
            >
              {/* Dom's Help Center */}
            </a>
          </div>
        </footer>
      </div>
    )
  }
}

export default Layout
